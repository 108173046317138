/**
 * constants shared by both client and server side
 */

const CMD_LOGIN = 'login'
const CMD_PING = 'ping'
const CMD_PONG = 'pong'
const CMD_GET_PROFILE = 'getProfile'
const CMD_NEW_SESSION = 'newSession'
const CMD_GET_SESSIONS = 'getSessions'
const CMD_GET_SESSION = 'getSession'
const CMD_RESUME_SESSION = 'resumeSession'

const CMD_SUBSCRIBE_TO_SESSION = 'subscribeToSession'
const CMD_UNSUBSCRIBE_FROM_SESSION = 'unsubscribeFromSession'

const CMD_UPDATE_EXCHANGE_ACCOUNTS = 'updateExchangeAccounts'

const CMD_GET_LOG_FILE = 'getLogFile'
const CMD_LOG_UPDATE = 'logUpdate'
const CMD_GET_FULL_STATE = 'fullState'

const CMD_TERMINATE_WORKER_PROCESS = 'terminateWorkerProcess'

const CMD_PREVIEW_ICEBERG = 'previewIceberg'
const CMD_EXECUTE_ICEBERG = 'executeIceberg'
const CMD_STOP_ICEBERG = 'stopIceberg'
const CMD_GET_ICEBERG_PARAMS = 'getIcebergParams'


const CMD_MAKER_TAKER_PREVIEW_TAKER = "makerTakerPreviewTaker"
const CMD_MAKER_TAKER_EXECUTE_TAKER = "makerTakerExecuteTaker"
const CMD_MAKER_TAKER_STOP_TAKER = "makerTakerStopTaker"
const CMD_MAKER_TAKER_PLACE_ORDER = "makerTakerPlaceOrder"
const CMD_MAKER_TAKER_GET_ACTIVE_ORDERS = "makerTakerGetActiveOrders"
const CMD_MAKER_TAKER_CANCEL_ORDER = "makerTakerCancelOrder"
const CMD_MAKER_TAKER_CANCEL_ALL_ORDERS = "makerTakerCancelAllOrders"
const CMD_MAKER_TAKER_CANCEL_OLD_ORDERS = "makerTakerCancelOldOrders"

const CMD_MAKER_TAKER_BROADCAST_ORDERBOOK = "broadcastMakerTakerOrderbook"
const CMD_MAKER_TAKER_BROADCAST_BASICINFO = "broadcastMakerTakerBasicInfo"
const CMD_MAKER_TAKER_BROADCAST_ACTIVE_ORDERS = "broadcastMakerTakerActiveOrders"
const CMD_MAKER_TAKER_BROADCAST_STARTED = "broadcastMakerTakerStarted"
const CMD_MAKER_TAKER_BROADCAST_STOPPED = "broadcastMakerTakerStopped"

const CMD_TOKEN_BUY_SELL_PREVIEW = "tokenBuySellPreview"
const CMD_TOKEN_BUY_SELL_EXECUTE = "tokenBuySellExecute"
const CMD_TOKEN_BUY_SELL_STOP = "tokenBuySellStop"

const CMD_TOKEN_BUY_SELL_PLACE_ORDER = "tokenBuySellPlaceOrder"

const CMD_TOKEN_BUY_SELL_CANCEL_ORDER = "tokenBuySellCancelOrder"

const CMD_TOKEN_BUY_SELL_BROADCAST_ORDERBOOK = "broadcastTokenBuySellOrderbook"
const CMD_TOKEN_BUY_SELL_BROADCAST_BASICINFO = "broadcastTokenBuySellBasicInfo"
const CMD_TOKEN_BUY_SELL_BROADCAST_STARTED = "broadcastTokenBuySellStarted"
const CMD_TOKEN_BUY_SELL_BROADCAST_STOPPED = "broadcastTokenBuySellStopped"
const CMD_TOKEN_BUY_SELL_BROADCAST_ACTIVE_ORDERS = "broadcastTokenBuySellActiveOrders"

const CMD_DEX_TAKER_PREVIEW_TAKER = "dexTakerPreviewTaker"
const CMD_DEX_TAKER_EXECUTE_TAKER = "dexTakerExecuteTaker"
const CMD_DEX_TAKER_STOP_TAKER = "dexTakerStopTaker"
const CMD_DEX_TAKER_GET_AMOUNT_B = "dexTakerGetAmountB"
const CMD_DEX_TAKER_PLACE_ORDER = "dexTakerPlaceOrder"
const CMD_DEX_TAKER_GET_BASIC_INFO = "dexTakerGetBasicInfo"
const CMD_DEX_TAKER_GET_TX_INFOS = "dexTakerGetTxInfos"
const CMD_DEX_TAKER_APPROVE_SPENDING = "dexTakerApproveSpending"
const CMD_DEX_TAKER_GET_SPENDING_INFOS = "dexTakerGetSpendingInfos"

const CMD_DEX_TAKER_BROADCAST_TX_INFOS = "dexTakerBroadcastTxInfos"
const CMD_DEX_TAKER_BROADCAST_BASIC_INFO = "broadcastDexTakerBasicInfo"
const CMD_DEX_TAKER_BROADCAST_SPENDING_INFOS = 'dexTakerBroadcastSpendingInfos'
const CMD_DEX_TAKER_BROADCAST_STARTED = "broadcastDexTakerStarted"
const CMD_DEX_TAKER_BROADCAST_STOPPED = "broadcastDexTakerStopped"

// broadcast
const CMD_BROADCAST_ICEBERG_ORDERBOOK = 'broadcastIcebergOb'
const CMD_BROADCAST_ICEBERG_BASIC_INFO = 'broadcastIcebergBasicInfo'
const CMD_BROADCAST_ICEBERG_ACTIVE_ORDER_TABLE = 'broadcastIcebergActiveOrderTable'
const CMD_BROADCAST_ICEBERG_STARTED = 'broadcastIcebergStarted'
const CMD_BROADCAST_ICEBERG_STOPPED = 'broadcastIcebergStopped'

const CMD_SPOOF_SNIPER_PREVIEW = "spoofSniperPreview"
const CMD_SPOOF_SNIPER_EXECUTE = "spoofSniperExecute"
const CMD_SPOOF_SNIPER_STOP = "spoofSniperStop"

const CMD_SPOOF_SNIPER_BROADCAST_ORDERBOOK = "broadcastSpoofSniperBroadcastOrderbook"
const CMD_SPOOF_SNIPER_BROADCAST_BASIC_INFO = "broadcastSpoofSniperBasicInfo"
const CMD_SPOOF_SNIPER_BROADCAST_STARTED = "broadcastSpoofSniperStarted"
const CMD_SPOOF_SNIPER_BROADCAST_STOPPED = "broadcastSpoofSniperStopped"
const CMD_SPOOF_SNIPER_BROADCAST_ACTIVE_ORDERS = "broadcastSpoofSniperActiveOrders"

// long short
const CMD_LONG_SHORT_PREVIEW = "cmdLongShortPreview"
const CMD_LONG_SHORT_EXECUTE = "cmdLongShortExecute"
const CMD_LONG_SHORT_STOP = "cmdLongShortStop"
const CMD_LONG_SHORT_BORROW_REPAY = "cmdLongShortBorrowRepay"
const CMD_LONG_SHORT_GET_ACTIVE_ORDERS = "cmdLongShortGetActiveOrders"
const CMD_LONG_SHORT_CANCEL_ORDER = "cmdLongShortCancelOrder"
const CMD_LONG_SHORT_PLACE_ORDER = "longShortPlaceOrder"
const CMD_LONG_SHORT_UPDATE_NOTE = "cmdLongShortUpdateNote"

const LONG_SHORT_MODE_NORMAL = "normal"
const LONG_SHORT_MODE_MAKER_WITH_TAKER = "makerWithTaker"

const TOKEN_BUY_SELL_MODE_NO_SPREAD = 'noSpread' // this is the default legacy mode
const TOKEN_BUY_SELL_MODE_WITH_SPREAD = 'withSpread'

const CMD_LONG_SHORT_BROADCAST_PARTIAL_FULL_STATE = "broadcastLongShortPartialFullState"
const CMD_LONG_SHORT_BROADCAST_STARTED = "broadcastLongShortStarted"
const CMD_LONG_SHORT_BROADCAST_STOPPED = "broadcastLongShortStopped"

const ALGO_KEY_MAKER_TAKER = 'maker_taker'
const ALGO_KEY_ICEBERG = 'iceberg'
const ALGO_KEY_TRADE_HISTORY = 'trade_history'
const ALGO_KEY_ASSET_MOVEMENT_HISTORY = 'asset_movement_history'
const ALGO_KEY_ASSET_LIST = 'asset_list'
const ALGO_KEY_PROJECT_ASSET_LIST = 'project_asset_list'
const ALGO_KEY_TOKEN_BUY_SELL = "token_buy_sell"
const ALGO_KEY_DEX_TAKER = 'dex_taker'
const ALGO_KEY_SPOOF_SNIPER = 'spoof_sniper'
const ALGO_KEY_LONG_SHORT = 'long_short'

const ACCOUNT_CAT_SPOT = 'spot'
const ACCOUNT_CAT_MARGIN = 'margin'
const ACCOUNT_CAT_FUTURE = 'future'
const ACCOUNT_CAT_DEX = 'dex'
const ACCOUNT_CAT_CONTRACT = 'contract'
const ACCOUNT_CAT_PORTFOLIO = 'portfolio'

const DEX_CHAIN_ETHER = 'ether'
const DEX_CHAIN_BSC = 'bsc'
const DEX_CHAIN_HECO = 'heco'
const DEX_CHAIN_MOONRIVER = 'moonriver'

const SESSION_STATUS_WAITING_FOR_WORKER = 'waitingForWorker'
const SESSION_STATUS_OPEN = 'open'
const SESSION_STATUS_CLOSED = 'close'

const SIDE_BUY = 'buy'
const SIDE_SELL = 'sell'

const OPTION_LONG = 'long'
const OPTION_SHORT = 'short'

const LONG_SHORT_OPTIONS = [{
  value: OPTION_LONG,
  label: 'Long',
},{
  value: OPTION_SHORT,
  label: 'Short'
}]

const OPTION_BORROW = 'borrow'
const OPTION_REPAY = 'repay'

const ICEBERG_ROW_STATUS_DONE_TEXT = 'Done'

const BUY_SELL_OPTIONS = [{
  value: SIDE_BUY,
  label: 'Buy'
},{
  value: SIDE_SELL,
  label: 'Sell',
}]

const BORROW_REPAY_OPTIONS = [{
  value: OPTION_BORROW,
  label: 'Borrow',
}, {
  value: OPTION_REPAY,
  label: 'Repay'
}]

const OPEN_POSITION = 'open'
const CLOSE_POSITION = 'close'

const POSITION_OPEN_CLOSE_OPTIONS = [{
  value: CLOSE_POSITION,
  label: 'Close',
}, {
  value: OPEN_POSITION,
  label: 'Open'
}]



const ORDER_STATUS_OPEN = 'open'
const ORDER_STATUS_CLOSED = 'closed'

const OPTION_TRUE = 'true'
const OPTION_FALSE = 'false'

const INFINITE_NUM = 1e18

/**
 * local defined numbers
 */
/**
 * hard coded precision, though different asset should have different precision
 * used in many places, which do not care much about precision 1) spamming, 2) borrowRepay
 */
const DEFAULT_ASSET_QTY_PRECISION = '0.00000001'


const ROLE_ADMIN = 'Admin'


export {
  CMD_LOGIN,
  CMD_PING,
  CMD_PONG,
  CMD_GET_PROFILE,
  CMD_NEW_SESSION,
  CMD_GET_SESSIONS,
  CMD_GET_SESSION,
  CMD_RESUME_SESSION,

  CMD_SUBSCRIBE_TO_SESSION,
  CMD_UNSUBSCRIBE_FROM_SESSION,
  CMD_UPDATE_EXCHANGE_ACCOUNTS,

  CMD_GET_LOG_FILE,
  CMD_LOG_UPDATE,
  CMD_GET_FULL_STATE,

  CMD_TERMINATE_WORKER_PROCESS,

  CMD_PREVIEW_ICEBERG,
  CMD_EXECUTE_ICEBERG,
  CMD_STOP_ICEBERG,
  CMD_GET_ICEBERG_PARAMS,
  CMD_BROADCAST_ICEBERG_ORDERBOOK,
  CMD_BROADCAST_ICEBERG_BASIC_INFO,
  CMD_BROADCAST_ICEBERG_ACTIVE_ORDER_TABLE,
  CMD_BROADCAST_ICEBERG_STARTED,
  CMD_BROADCAST_ICEBERG_STOPPED,

  CMD_MAKER_TAKER_PREVIEW_TAKER,
  CMD_MAKER_TAKER_EXECUTE_TAKER,
  CMD_MAKER_TAKER_STOP_TAKER,
  CMD_MAKER_TAKER_PLACE_ORDER,
  CMD_MAKER_TAKER_GET_ACTIVE_ORDERS,
  CMD_MAKER_TAKER_CANCEL_ORDER,
  CMD_MAKER_TAKER_CANCEL_ALL_ORDERS,
  CMD_MAKER_TAKER_CANCEL_OLD_ORDERS,

  CMD_MAKER_TAKER_BROADCAST_ORDERBOOK,
  CMD_MAKER_TAKER_BROADCAST_BASICINFO,
  CMD_MAKER_TAKER_BROADCAST_ACTIVE_ORDERS,
  CMD_MAKER_TAKER_BROADCAST_STARTED,
  CMD_MAKER_TAKER_BROADCAST_STOPPED,

  CMD_TOKEN_BUY_SELL_PREVIEW,
  CMD_TOKEN_BUY_SELL_EXECUTE,
  CMD_TOKEN_BUY_SELL_STOP,
  CMD_TOKEN_BUY_SELL_PLACE_ORDER,
  CMD_TOKEN_BUY_SELL_CANCEL_ORDER,

  CMD_TOKEN_BUY_SELL_BROADCAST_ORDERBOOK,
  CMD_TOKEN_BUY_SELL_BROADCAST_BASICINFO,
  CMD_TOKEN_BUY_SELL_BROADCAST_STARTED,
  CMD_TOKEN_BUY_SELL_BROADCAST_STOPPED,
  CMD_TOKEN_BUY_SELL_BROADCAST_ACTIVE_ORDERS,

  CMD_DEX_TAKER_PREVIEW_TAKER,
  CMD_DEX_TAKER_EXECUTE_TAKER,
  CMD_DEX_TAKER_STOP_TAKER,
  CMD_DEX_TAKER_GET_AMOUNT_B,
  CMD_DEX_TAKER_PLACE_ORDER,
  CMD_DEX_TAKER_GET_BASIC_INFO,
  CMD_DEX_TAKER_GET_TX_INFOS,
  CMD_DEX_TAKER_APPROVE_SPENDING,
  CMD_DEX_TAKER_GET_SPENDING_INFOS,

  CMD_DEX_TAKER_BROADCAST_TX_INFOS,
  CMD_DEX_TAKER_BROADCAST_BASIC_INFO,
  CMD_DEX_TAKER_BROADCAST_SPENDING_INFOS,
  CMD_DEX_TAKER_BROADCAST_STARTED,
  CMD_DEX_TAKER_BROADCAST_STOPPED,

  CMD_SPOOF_SNIPER_PREVIEW,
  CMD_SPOOF_SNIPER_EXECUTE,
  CMD_SPOOF_SNIPER_STOP,

  CMD_SPOOF_SNIPER_BROADCAST_ORDERBOOK,
  CMD_SPOOF_SNIPER_BROADCAST_BASIC_INFO,
  CMD_SPOOF_SNIPER_BROADCAST_STARTED,
  CMD_SPOOF_SNIPER_BROADCAST_STOPPED,
  CMD_SPOOF_SNIPER_BROADCAST_ACTIVE_ORDERS,

  CMD_LONG_SHORT_PREVIEW,
  CMD_LONG_SHORT_EXECUTE,
  CMD_LONG_SHORT_STOP,
  CMD_LONG_SHORT_BORROW_REPAY,
  CMD_LONG_SHORT_GET_ACTIVE_ORDERS,
  CMD_LONG_SHORT_CANCEL_ORDER,
  CMD_LONG_SHORT_PLACE_ORDER,
  CMD_LONG_SHORT_UPDATE_NOTE,

  LONG_SHORT_MODE_NORMAL,
  LONG_SHORT_MODE_MAKER_WITH_TAKER,

  TOKEN_BUY_SELL_MODE_NO_SPREAD,
  TOKEN_BUY_SELL_MODE_WITH_SPREAD,

  CMD_LONG_SHORT_BROADCAST_PARTIAL_FULL_STATE,
  CMD_LONG_SHORT_BROADCAST_STARTED,
  CMD_LONG_SHORT_BROADCAST_STOPPED,

  ALGO_KEY_MAKER_TAKER,
  ALGO_KEY_ICEBERG,
  ALGO_KEY_TRADE_HISTORY,
  ALGO_KEY_ASSET_MOVEMENT_HISTORY,
  ALGO_KEY_ASSET_LIST,
  ALGO_KEY_PROJECT_ASSET_LIST,
  ALGO_KEY_TOKEN_BUY_SELL,
  ALGO_KEY_DEX_TAKER,
  ALGO_KEY_SPOOF_SNIPER,
  ALGO_KEY_LONG_SHORT,

  ACCOUNT_CAT_SPOT,
  ACCOUNT_CAT_MARGIN,
  ACCOUNT_CAT_FUTURE,
  ACCOUNT_CAT_DEX,
  ACCOUNT_CAT_CONTRACT,
  ACCOUNT_CAT_PORTFOLIO,

  OPTION_LONG,
  OPTION_SHORT,

  LONG_SHORT_OPTIONS,

  OPTION_BORROW,
  OPTION_REPAY,

  DEX_CHAIN_ETHER,
  DEX_CHAIN_BSC,
  DEX_CHAIN_HECO,
  DEX_CHAIN_MOONRIVER,

  SIDE_SELL,
  SIDE_BUY,
  BUY_SELL_OPTIONS,

  BORROW_REPAY_OPTIONS,

  OPEN_POSITION,
  CLOSE_POSITION,
  POSITION_OPEN_CLOSE_OPTIONS,

  SESSION_STATUS_CLOSED,
  SESSION_STATUS_OPEN,
  SESSION_STATUS_WAITING_FOR_WORKER,

  ORDER_STATUS_OPEN,
  ORDER_STATUS_CLOSED,

  ICEBERG_ROW_STATUS_DONE_TEXT,

  OPTION_TRUE,
  OPTION_FALSE,

  INFINITE_NUM,
  DEFAULT_ASSET_QTY_PRECISION,

  ROLE_ADMIN
}
