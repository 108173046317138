import React from 'react'
import lodash from 'lodash'
import clsx from 'clsx'
import { Box, Table, TableBody, Button, TableRow, makeStyles } from '@material-ui/core'
import ConfirmDialog from '../../../../components/ConfirmDialog'
import {
  CMD_LONG_SHORT_EXECUTE,
  CMD_LONG_SHORT_STOP, SIDE_BUY, SIDE_SELL,
} from '../../../../protocolConstants'
import PropTypes from 'prop-types'
import { longShortDialogPropType } from '../../../../utils/propTypesUtils'
import { mapDispatch } from '../../../../utils/utils'
import { actions as singleActions } from '../../../single/singleSlice'
import connect from 'react-redux/es/connect/connect'
import PiTableCell from '../../../../components/PiTableCell'

const useStyles = makeStyles((theme) => ({
  lineThrough: {
    'text-decoration': 'line-through',
  },
  tableCellGreen: {
    color: '#0f8f62',
  },
  tableCellRed: {
    color: '#d9304f',
  },
}))

const COLOR_RED = 'red'
const COLOR_GREEN = 'green'

const LsCell = ({children, isLineThrough=false,color = '', colSpan=1, rowSpan = 1, isHeader=false}) => {
  const classes = useStyles()
  let className = ''
  if (color === COLOR_RED) {
    className = classes.tableCellRed
  } else if (color === COLOR_GREEN) {
    className = classes.tableCellGreen
  }
  if (isLineThrough) {
    className = clsx(classes.lineThrough, className)
  }
  return (
    <PiTableCell
      variant={isHeader ? 'head' : undefined}
      colSpan={colSpan}
      rowSpan={rowSpan}
      className={className}
    >
      {children}
    </PiTableCell>
  )
}

const LsTableRow = ({ isHeader= false, name='', values=[], valueColSpan=1, valueRowSpans = [], isLineThrough=false, color = ''}) => {
  return (
    <TableRow hover>
      <LsCell
        isHeader
        isLineThrough={isLineThrough}
      >
        {name}
      </LsCell>
      {lodash.map(values, (val, idx) => {

        let rowSpan = 1
        if (valueRowSpans.length > idx) {
          rowSpan = valueRowSpans[idx]
        }
        return (
          <LsCell
            isHeader={isHeader}
            isLineThrough={isLineThrough}
            color={color}
            key={`cell_idx_${idx}`}
            colSpan={valueColSpan}
            rowSpan={rowSpan}
          >
            {val}
          </LsCell>
        )
      })}
    </TableRow>
  )
}

const isBuyToSideStr = (isBuy) => {
  if (isBuy === true) {
    return SIDE_BUY
  } else if (isBuy === false) {
    return SIDE_SELL
  } else {
    return "ERROR"
  }
}

const LongShortTargetRow = ({isLineThrough, ...longShortTarget}) => {
  const {
    pairA, targetQtyA, orderQtyA, isBuyA,
    pairB, targetQtyB, orderQtyB, isBuyB,
    initABSum,
  } = longShortTarget
  const ret = []

  const sideA = isBuyToSideStr(isBuyA)
  const sideB = isBuyToSideStr(isBuyB)

  ret.push(
    <LsTableRow
        key='longShortTargetA'
        name={`Acc A ${pairA}`}
        isLineThrough={isLineThrough}
        values={[initABSum, targetQtyA, orderQtyA, sideA]}
        valueRowSpans={[2]}
    />
  )

  ret.push(
    <LsTableRow
      key='longShortTargetB'
      name={`Acc B ${pairB}`}
      isLineThrough={isLineThrough}
      values={[targetQtyB, orderQtyB, sideB]}
    />
  )
  return ret
}

const LongShortRows = (previewData) => {
  let { longShortEnabled, longShortItvlMs, longShortMode,
    longShortAutoDeleverageEnabled, longShortStopOnComplete, longShortTargets } = previewData
  if (!longShortTargets) {
    longShortTargets = []
  }
  const isLineThrough = !longShortEnabled

  let longShortStopOnCompleteColor = ''
  if (longShortStopOnComplete === true) {
    longShortStopOnCompleteColor = COLOR_GREEN
  } else if (longShortStopOnComplete === false){
    longShortStopOnCompleteColor = COLOR_RED
  }

  const ret = [
    <LsTableRow
      key='longShortEnabled'
      name='Long Short Enabled'
      values={[lodash.toString(longShortEnabled)]}
      valueColSpan={4}
    />,
    <LsTableRow
      key='longShortItvlMs'
      name='Long Short Itvl Ms'
      isLineThrough={isLineThrough}
      values={[longShortItvlMs]}
      valueColSpan={4}
    />,

    <LsTableRow
      key='longShortMode'
      name='Long Short Mode'
      isLineThrough={isLineThrough}
      values={[longShortMode]}
      valueColSpan={4}
    />,
    <LsTableRow
      key='longShortAutoDeleverageEnabled'
      name='Auto Deleverage Enabled'
      isLineThrough={isLineThrough}
      values={[lodash.toString(longShortAutoDeleverageEnabled)]}
      valueColSpan={4}
    />,

    <LsTableRow
      key='longShortStopOnComplete'
      name='Long Short Stop On Complete'
      isLineThrough={isLineThrough}
      color={longShortStopOnCompleteColor}
      values={[lodash.toString(longShortStopOnComplete)]}
      valueColSpan={4}
    />,
    <LsTableRow
      key='longShortTargetsAcc'
      isHeader
      isLineThrough={isLineThrough}
      values={['Tgt AB Sum', 'Tgt Qty', 'Order Qty', 'isBuy']}
    />,
  ]
  return lodash.concat(ret, lodash.map(longShortTargets, (target, idx) => {
    return (
      <LongShortTargetRow
        isLineThrough={isLineThrough}
        {...target}
        key={`longShortTarget_${idx}`}
      />
    )
  }))
}

const LongShortDeleverageRows = (previewData) => {
  let { longShortEnabled, longShortAutoDeleverageEnabled, longShortAutoDeleverageTargets } = previewData
  if (!longShortAutoDeleverageTargets) {
    longShortAutoDeleverageTargets = []
  }
  const isLineThrough = !longShortEnabled || !longShortAutoDeleverageEnabled

  let longShortAutoDeleverageEnabledColor = ''
  if (longShortAutoDeleverageEnabled === true) {
    longShortAutoDeleverageEnabledColor = COLOR_GREEN
  } else if (longShortAutoDeleverageEnabled === false){
    longShortAutoDeleverageEnabledColor = COLOR_RED
  }

  const ret = [
    <LsTableRow
      key='longShortAutoDeleverageEnabled'
      name='Auto Deleverage Enabled'
      isLineThrough={isLineThrough}
      color={longShortAutoDeleverageEnabledColor}
      values={[lodash.toString(longShortAutoDeleverageEnabled)]}
      valueColSpan={2}
    />,
    <LsTableRow
      key='longShortAutoDeleverageTargetsAcc'
      isHeader
      isLineThrough={isLineThrough}
      values={['AccName', 'Start Delev', 'End Delev']}
    />,
  ]
  lodash.forEach(longShortAutoDeleverageTargets, (target, idx) => {
    const { delevAccName, startDelevLeverage, endDelevLeverage} = target
    ret.push(
      <LsTableRow
        key='longShortTargetsAcc'
        isHeader
        isLineThrough={isLineThrough}
        values={[delevAccName, startDelevLeverage, endDelevLeverage]}
      />,
    )
  })
  return ret
}


const SpammingRows = (previewData) => {
  const {
    spammingEnabled,
    spammingItvlMs,
    spammingAccName,
    spammingTargets = [],
  } = previewData
  const isLineThrough = !spammingEnabled
  const spammingRowData = [
    {
      key: 'spamming_enabled',
      name: 'Spm. Enabled',
      value: lodash.toString(spammingEnabled)
    },
    {
      key: 'spamming_accname',
      name: 'Spm. AccName',
      value: spammingAccName,
    },
    {
      key: 'spamming_itvlms',
      name: 'Spm. Itvl Ms',
      value: spammingItvlMs
    }
  ]
  const ret = lodash.map(spammingRowData, (rd) => {
    const { key, name, value } = rd
    return (
      <LsTableRow
        valueColSpan={2}
        key={key}
        name={name}
        isLineThrough={isLineThrough}
        values={[value]}
      />
    )
  })
  ret.push(
      <LsTableRow
        key='spamming_asset'
        name='Spm. Asset'
        isHeader
        isLineThrough={isLineThrough}
        values={['Target Qty', 'Order Qty']}
      />
  )
  return lodash.concat(ret, lodash.map(spammingTargets, (target, idx) => {
    const { asset, targetQty, orderQty } = target
    return (
      <LsTableRow
        key={`spamming_target_${idx}`}
        name={asset}
        values={[targetQty, orderQty]}
        isLineThrough={isLineThrough}
      />
    )
  }))
}

const DialogContentExecuteLongShort = ({previewData}) => {
  const {
    warningMsg,
  } = previewData

  return (
    <Box mb={1}>
      <Table>
        <TableBody>
          <LongShortRows
            {...previewData}
          />
          <TableRow key='longShortSpammingDivider'>
            <PiTableCell
              variant='head'
              colSpan={5}
            >
            </PiTableCell>
          </TableRow>
        </TableBody>
      </Table>

      <Table>
        <TableBody>
          <LongShortDeleverageRows
            {...previewData}
          />
          <TableRow key='longShortSpammingDivider'>
            <PiTableCell
              variant='head'
              colSpan={5}
            >
            </PiTableCell>
          </TableRow>
        </TableBody>
      </Table>

      <Table>
        <TableBody>
          <SpammingRows
            {...previewData}
          />
          {lodash.isEmpty(warningMsg)
            ? null
            :
            <TableRow hover>
              <PiTableCell
                variant='head'
              >
                Warning Msg
              </PiTableCell>
              <PiTableCell
                colSpan={2}
                align='center'
              >
                {warningMsg}
              </PiTableCell>
            </TableRow>
          }
        </TableBody>
      </Table>
    </Box>
  )
}


const LongShortFormDialog = ({dialogData, setCurPageData, sendReq, sessionId}) => {
  if (!dialogData) {
    return null
  }
  const {
    dialogOpen,
    dialogType,
    previewData,  // preview data, preview sent data for execute iceberg
    previewSentData,
  } = dialogData
  const hideDialog = () => {
    setCurPageData({
      dialogData: {
        ...dialogData,
        dialogOpen: false,
      }
    })
  }

  if (dialogType === 'executeLongShort') {
    const executeLongShort = () => {
      sendReq({
        cmd: CMD_LONG_SHORT_EXECUTE,
        isRelay: true,
        data: lodash.get(previewSentData, 'data')
      })
      hideDialog()
    }
    const confirmButton = <Button onClick={executeLongShort}>
      Execute Now
    </Button>
    return (
      <ConfirmDialog
        dialogOpen={dialogOpen}
        dialogTitle='Are you sure you want to execute long short?'
        maxWidth='lg'
        dialogContent={<DialogContentExecuteLongShort
          previewData={previewData}
        />}
        dialogCancelText='Dismiss'
        dialogConfirmButton={confirmButton}
        dialogOnCancel={hideDialog}
      />
    )
  } else if (dialogType === 'stopLongShort') {
    const stopLongShort = () => {
      sendReq({
        cmd: CMD_LONG_SHORT_STOP,
        isRelay: true,
        data: {
          sessionId
        }
      })
      hideDialog()
    }
    return (
      <ConfirmDialog
        dialogOpen={dialogOpen}
        dialogTitle='Are you sure you want to stop Long Short?'
        dialogContent=''
        dialogCancelText='Dismiss'
        dialogConfirmButton='Stop now'
        dialogOnCancel={hideDialog}
        dialogOnConfirm={stopLongShort}
      />
    )
  } else {
    return null
  }
}


LongShortFormDialog.propTypes = {
  dialogData: longShortDialogPropType,
  setCurPageData: PropTypes.func.isRequired,
  sendReq: PropTypes.func.isRequired,
  sessionId: PropTypes.number.isRequired
}

LongShortFormDialog.defaultProps= {

}

const mapStateToProps = (state, ownProps)=> {
  const dialogData = lodash.get(state, ['single', 'curPageData', 'dialogData'])
  return {
    dialogData
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return mapDispatch(dispatch)(singleActions)
}

export default connect(mapStateToProps, mapDispatchToProps)(LongShortFormDialog)
