import React, { useCallback, useEffect } from 'react'
import * as Yup from 'yup'
import { Formik } from 'formik'
import {
  Box,
  makeStyles,
} from '@material-ui/core'
import { mapDispatch } from '../../../../utils/utils'
import { actions as socketActions } from '../../../socket/socketSlice'
import { actions as singleActions, showToast } from '../../../single/singleSlice'
import connect from 'react-redux/es/connect/connect'
import PropTypes from 'prop-types'
import lodash from 'lodash'
import { useRefFields, useRefFieldsPrevious, useStateReq } from '../../../../utils/hooks'
import {
  CMD_LONG_SHORT_EXECUTE,
  CMD_LONG_SHORT_PREVIEW,
  CMD_LONG_SHORT_STOP, LONG_SHORT_MODE_MAKER_WITH_TAKER, LONG_SHORT_MODE_NORMAL,
  SIDE_BUY, SIDE_SELL,
} from '../../../../protocolConstants'
import { longShortAccCfgPropTypes } from '../../../../utils/propTypesUtils'
import { accStatePropTypes, createEmptyLongShortTarget } from './longShortUtils'
import LongShortFormInner from './LongShortFormInner'
import { PiFormContextProvider } from '../../../../utils/piFormContext'
import SpammingFormDialog from './LongShortFormDialog'


const useStyles = makeStyles((theme) => ({
  formBox: {
  },
  bidAskButton: {
    color: "#000000",
    height: 'auto',
  }
}))

const LongShortForm = ({ sessionId, dialogData, paramLongShort, accCfgA, accCfgB, accStateA, accStateB, setCurPageData, placeOrderFormUpdatingData }) => {
  const classes = useStyles()
  const {
    isSending,
    isSuccessful,
    isError,
    sendReq,
    getStateField,
    setStateField,
    receivedData,
    sentData,
  } = useStateReq()
  const onPreviewLongShort = (values, { setErrors }) => {
    setStateField('valueErrors', undefined)
    values = lodash.clone(values)
    // set the function into the ref
    setRefField('setErrors', setErrors)
    sendReq({
      cmd: CMD_LONG_SHORT_PREVIEW,
      isRelay: true,
      data: {
        sessionId,
        params: {
          ...values
        }
      }
    })
  }
  const onStopLongShort = useCallback(() => {
    const dialogOpen = true
    const dialogType = 'stopLongShort'
    setCurPageData({
      dialogData: {
        ...dialogData,
        dialogOpen,
        dialogType
      }
    })
  }, [dialogData, setCurPageData])

  const showPreviewLongShortDialog = useCallback((previewDataInput, previewSentDataInput) => {
    const dialogOpen = true
    const dialogType = 'executeLongShort'
    setCurPageData({
      dialogData: {
        ...dialogData,
        dialogOpen,
        dialogType,
        previewData: previewDataInput,
        previewSentData: previewSentDataInput
      }
    })
  }, [setCurPageData, dialogData])

  const active = lodash.get(paramLongShort, 'active', false)
  const valueErrors = getStateField('valueErrors', undefined)
  const [setRefField, getRefField] = useRefFields()
  const prevIsSending  = useRefFieldsPrevious('isSending', isSending)
  useEffect(() => {
    if (prevIsSending && !isSending) {
      const cmd = lodash.get(sentData, 'cmd')
      if(cmd === CMD_LONG_SHORT_PREVIEW) {
        if (isSuccessful) {
          const previewData = lodash.get(receivedData, 'data')
          showPreviewLongShortDialog(previewData, sentData)
        } else if (isError) {
          const valueErrors = lodash.get(receivedData, ['data', 'valueErrors'])
          const formErrors = lodash.get(receivedData, ['data', 'formErrors'])
          setStateField('valueErrors', valueErrors)
          const setErrors = getRefField('setErrors')
          if (!lodash.isEmpty(formErrors) && lodash.isFunction(setErrors)) {
            setErrors(formErrors)
          }
        }
      } else if (cmd === CMD_LONG_SHORT_EXECUTE) {
        if (isSuccessful) {
          showToast('Long Short executed successfully', 'success')
          const active = true
          setCurPageData({
            // params from props, hence immutable
            paramLongShort: {
              ...paramLongShort,
              active
            }
          })
        } else if (isError) {
        }
      } else if (cmd === CMD_LONG_SHORT_STOP) {
        if (isSuccessful) {
          showToast("Long Short stopped successfully", 'success')
          const active = false
          setCurPageData({
            // params from props, hence immutable
            paramLongShort: {
              ...paramLongShort,
              active
            }
          })
        } else if (isError) {

        }
      }
    }
  }, [prevIsSending, paramLongShort, setCurPageData, getRefField, isSending, isSuccessful,
    isError, receivedData, sentData, setStateField, showPreviewLongShortDialog])

  if (!accCfgA || !accCfgB) {
    return null
  }
  const { pairs: pairsA } = accCfgA
  const { pairs: pairsB } = accCfgB
  if (lodash.size(pairsA) === 0 || lodash.size(pairsB) === 0) {
    return null
  }

  return (
    <Box
      className={classes.formBox}
      display="flex"
      flexDirection="column"
      justifyContent="center"
    >
      <Formik
        initialValues={{
          spammingTargets:[{}],
          longShortTargets: [createEmptyLongShortTarget()],
          ...paramLongShort
        }}
        validationSchema={props =>  Yup.lazy(values => {
          let schema = {
            longShortEnabled: Yup.boolean(),
            spammingEnabled: Yup.boolean(),
          }
          const { spammingEnabled, longShortEnabled, longShortAutoDeleverageEnabled } = values
          if (spammingEnabled) {
            schema = {
              ...schema,
              spammingAccName: Yup.string().required('Account is required'),
              spammingTargets: Yup.array().of(Yup.object().shape({
                asset: Yup.string().required("Asset is required").nullable(),
                targetQty: Yup.number().required('Target Qty is required').moreThan(0, "Must be positive"),
                orderQty: Yup.number().required('Order Qty is required').moreThan(0, 'Must be positive'),
              })),
              spammingItvlMs: Yup.number().required('Spamming Iterval is required').moreThan(0, "Must be positive"),
            }
          }
          if (longShortEnabled) {
            schema = {
              ...schema,
              longShortTargets: Yup.array().of(Yup.object().shape({
                pairA: Yup.string().required('PairA is required'),
                targetQtyA: Yup.number().required("Target Qty A is required"),
                orderQtyA: Yup.number().required('OrderQtyA is required').moreThan(0, 'Must be positive'),
                sideA: Yup.string().oneOf([SIDE_BUY, SIDE_SELL]).required('sideA is required'),
                initABSum: Yup.number().required('Init A B Sum is required')
              })),
              longShortItvlMs: Yup.number().required('LongShort Iterval is required').moreThan(0, "Must be positive"),
              longShortMode: Yup.string().oneOf([LONG_SHORT_MODE_NORMAL, LONG_SHORT_MODE_MAKER_WITH_TAKER]).required('Mode is required').nullable(),
              longShortAutoDeleverageEnabled: Yup.bool().required('Auto deleverage is required'),
            }

            if (longShortAutoDeleverageEnabled) {
              schema = {
                ...schema,
                longShortAutoDeleverageTargets: Yup.array().of(Yup.object().shape({
                  delevAccName: Yup.string().required('Delev Acc Name is required'),
                  startDelevLeverage: Yup.number().required('Start Leverage is required').moreThan(0, "Lev should more than 0"),
                  endDelevLeverage: Yup.number().required('End Leverage is required').moreThan(0, "Lev should more than 0"),
                }))
              }
            }

            let longShortStopOnCompleteFalseOnly = false
            if (longShortAutoDeleverageEnabled) {
              longShortStopOnCompleteFalseOnly = true
            }
            if (spammingEnabled) {
              longShortStopOnCompleteFalseOnly = true
            }

            if (longShortStopOnCompleteFalseOnly) {
              schema = {
                ...schema,
                longShortStopOnComplete: Yup.bool().oneOf([false], 'Stop On Complete must be false, when auto deleverage or spamming is enabled')
              }
            } else {
              schema = {
                ...schema,
                longShortStopOnComplete: Yup.bool().required('LongShort Stop On Complete is required'),
              }
            }
          }
          return Yup.object().shape(schema)
        })}
        onSubmit={onPreviewLongShort}
      >
        {(formProps) => {
          return (
            <PiFormContextProvider
              disabledAllFields={active}
            >
              <LongShortFormInner
                active={active}
                accCfgA={accCfgA}
                accCfgB={accCfgB}
                accStateA={accStateA}
                accStateB={accStateB}
                isSending={isSending}
                valueErrors={valueErrors}
                formProps={formProps}
                setCurPageData={setCurPageData}
                onStopLongShort={onStopLongShort}
                placeOrderFormUpdatingData={placeOrderFormUpdatingData}
              />
            </PiFormContextProvider>
          )
        }}
      </Formik>

      <SpammingFormDialog
        sessionId={sessionId}
        sendReq={sendReq}
      />
    </Box>
  )
}


LongShortForm.propTypes = {
  sessionId: PropTypes.number.isRequired,
  placeOrderFormUpdatingData: PropTypes.object,
  accCfgA: longShortAccCfgPropTypes,
  accCfgB: longShortAccCfgPropTypes,
  accStateA: accStatePropTypes,
  accStateB: accStatePropTypes,

  paramLongShort: PropTypes.object,
}

LongShortForm.defaultProps = {
  algorithms: [],
  paramLongShort: {}
}

const mapStateToProps = (state, ownProps)=> {
  const accCfgA = lodash.get(state, ['single', 'curPageData', 'accCfgA'])
  const accCfgB = lodash.get(state, ['single', 'curPageData', 'accCfgB'])
  const accStateA = lodash.get(state, ['single', 'curPageData', 'accStateA'])
  const accStateB = lodash.get(state, ['single', 'curPageData', 'accStateB'])
  const paramLongShort = lodash.get(state, ['single', 'curPageData', 'paramLongShort'])
  return {
    accCfgA,
    accCfgB,
    accStateA,
    accStateB,
    paramLongShort,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return mapDispatch(dispatch)({...socketActions, ...singleActions})
}

export default connect(mapStateToProps, mapDispatchToProps)(LongShortForm)



